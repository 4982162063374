import type { NextPage } from 'next'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import FullScreenLoader from '../components/shared/FullScreenLoader'

const Home: NextPage = () => {
  const { replace } = useRouter()

  useEffect(() => {
    replace('/communes')
  }, [replace])

  return <FullScreenLoader />
}

export default Home
